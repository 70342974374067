// This SCSS generates the css for the general styles
// To keep things simple, it uses the standard variables of
// bootstrap 4 conventions.

@media all {
  /* Linkblock with Image and Images with Caption */
  figure {
    position: relative;
    overflow: hidden;    
    transition: background-color 0.3s ease-in-out;
        
    img {
      transition: transform .5s ease, opacity 0.3s ease-in-out;
    }
    
    figcaption {
      position: absolute;
      z-index: 2;
      left: 0;
      bottom: 0;
      width: 100%;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 5%, rgba(0, 0, 0, 0.7) 100%);
    }
  }

  a:hover {
    figure {
      &.effect-zoom {
        img {
          transform: scale(1.05);
        }
      }

      &.effect-darken {
        img {
          opacity: 0.8;
        }
      }
    }
  }

  .bg-primary.placeholder {
    padding-bottom: 58%;
    margin-bottom:1rem;
  }

  .alert p:last-child {
    margin-bottom: 0;
  }

  a {
    /* video link */
    &.co-video {
      display: block;
      position: relative;

      &.co-video-no-image {
        background-color: color-mix(in srgb, transparent, var(--bs-dark, var(--bs-dark-fallback-app-content)) 40%);
        
        > svg {
          position: relative;
          transform: translate(-50%, 0);
          margin: 10px 0;
        }

        figure {
          .overlay {
            position: static;
            background-color: color-mix(in srgb, transparent, var(--bs-dark, var(--bs-dark-fallback-app-content)) 40%);
          }
        }
        
      }
      
      figure {
        z-index: 0;
      }

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        filter: drop-shadow(0px 0px 8px rgb(0 0 0 / 0.3));
        height: 54px;
        .st0,
        .st1 {
          transition: fill 0.3s ease-in-out;
        }
      }

      &:hover {
        svg {
          .st0 {
            fill: red !important;
            filter: none;
          }
          .st1 {
            fill: #fff !important;
            filter: none;
          }
        }
      }
    }
  }

  .co-linkblock {
    color: var(--bs-body-color, var(--bs-body-color-fallback-app-content));   
    position: relative;

    &:hover {
      figure {
        &.effect-zoom {
          img {
            transform: scale(1.05);
          }
        }

        &.effect-darken {
          background-color: var(--bs-primary, var(--bs-primary-fallback-app-content));

          img {
            opacity: 0.8;
          }
        }
      } 
    }
    
    a {
      text-decoration: none;
      color: var(--bs-link-color, var(--bs-link-color-fallback-app-content));
      
      &:hover {
        svg {
          transition: margin-left 0.3s ease-in-out;
          margin-left: 8px;
        }               
      }

      svg {
        transition: margin-left 0.3s ease-in-out;
        margin-left: 0;
        vertical-align: text-bottom;
        margin-bottom: 3px;
        height: 12px;
      }
    }

    p {
      color: var(--bs-body-color, var(--bs-body-color-fallback-app-content));
    }  

    &.co-linkblock-overlay {
      color: var(--bs-white);

      h4, p, a {
        color: var(--bs-white);
      } 
      
      figure {
        .overlay {
          position: absolute;
          z-index: 1;
          left: 0;
          top: 0;
          background-color: color-mix(in srgb, transparent, var(--bs-dark, var(--bs-dark-fallback-app-content)) 40%);
          transition: background-color 0.3s ease-in-out;
        }
      }
    }
  }

  .co-video-embed {
    picture {
      position: absolute
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      max-width: none;
    }

    .overlay {
      position: absolute;
      z-index: 1;
      left: 0;
      top: 0;
      background-color: color-mix(in srgb, transparent, var(--bs-dark, var(--bs-dark-fallback-app-content)) 40%);
      transition: background-color 0.3s ease-in-out;
      cursor: pointer;

      &.hide {
        display: none !important;
      }

      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        filter: drop-shadow(0px 0px 8px rgb(0 0 0 / 0.3));
        height: 54px;
        .st0,
        .st1 {
          transition: fill 0.3s ease-in-out;
        }
      }
      &:hover {
        svg {
          .st0 {
            fill: red !important;
            filter: none;
          }
          .st1 {
            fill: #fff !important;
            filter: none;
          }
        }
      }
    }
  }
}
